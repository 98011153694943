







import { defineComponent } from '@vue/composition-api'


export default defineComponent({
  name: 'time-badge',
  props: {
    time: {
      type: String,
      required: true
    }
  },
  setup ({ time }) {
    const formatedTime : String = time.length === 5 ? time : time.slice(0, 5)

    return {
      formatedTime
    }
  }
})
