




















































import { defineComponent, reactive, watch, PropType, ref } from '@vue/composition-api'
import useRepository, { callTypes } from '../../composables/useRepository'
import BaseRepository, { repoParams } from '../../repositories/baseRepository'
import { useRouter } from '@/composables/useRouter'
import useGlobalLoading from '@/composables/useGlobalLoading'
import usePermissions from '@/composables/usePermissions'

export default defineComponent({
  name: 'base-overview',
  components: {
  },
  props: {
    filtersProp: {
      type: Object,
      required: false
    },
    repo: {
      type: Function as PropType<new (...params: any[]) => BaseRepository>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    createRoute: {
      type: String,
      required: false
    },
    showFilters: {
      type: Boolean,
      default: true
    },
    showLoadMore: {
      type: Boolean,
      default: true
    },
    filtersInUrlParams: {
      type: Boolean,
      default: true
    },
    createPermission: String
  },
  setup ({ repo, filtersProp, filtersInUrlParams }) {
    const { router, route } = useRouter()
    let filters : any = {}

    // set prop values on clean filter value to remove observer
    if (filtersProp) {
      Object.keys(filtersProp).forEach((key: any) => { filters[key] = filtersProp[key] })
    }

    if (route.value.query.filters) {
      filters = JSON.parse(window.atob(route.value.query.filters.toString()))
    }

    let callParams = reactive<repoParams>({
      filters: filters
    })
    const { loading, doCall, results, loadMore } = useRepository(repo, callTypes.getModelArray, callParams)
    useGlobalLoading(loading)
    doCall()
    const { can } = usePermissions()


    watch(callParams, value => {
      if (callParams?.filters?.type?.value === 'METHODIC') {
        callParams.filters.theme.value = undefined
      }


      if (callParams?.filters?.type?.value === 'THEMATIC') {
        callParams.filters.category.value = undefined
      }

      let emptyFilters = true
      callParams.filters && Object.keys(callParams.filters).forEach((key: any) => {
        if (callParams.filters && callParams.filters[key].value !== undefined) {
          emptyFilters = false
        }
      })

      if (emptyFilters) {
        filtersInUrlParams && router.replace({ query: { } })
      } else {
        filtersInUrlParams && router.replace({ query: { filters: window.btoa(JSON.stringify(callParams.filters)) } })
      }

      callParams.page = 1
      doCall().then(() => {
      })
    })

    const resetFilers = () => {
      callParams.filters && Object.keys(callParams.filters).forEach(filterKey => {
        if (callParams.filters) {
          callParams.filters[filterKey].value = undefined
        }
      })
    }

    return {
      results,
      resetFilers,
      callParams,
      loadMore,
      loading,
      can
    }
  }
})
