











import Vue from 'vue'
import Header from './components/semantic/Header.vue'
import Footer from './components/semantic/Footer.vue'
import BasePage from './components/semantic/BasePage.vue'
import GlobalLoader from './components/semantic/GlobalLoader.vue'

export default Vue.extend({
  name: 'scouts-wwdb',
  components: {
    'custom-header': Header,
    'custom-footer': Footer,
    'base-page': BasePage,
    'global-loader': GlobalLoader
  }
})
