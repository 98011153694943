















































































































import { defineComponent, ref, Ref, PropType, watch } from '@vue/composition-api'
import BuildingBlocskRepository from '../../repositories/entities/privateBuildingBlocskRepository'
import BaseOverview from '../../components/base-views/baseOverview.vue'
import useRepository, { callTypes } from '@/composables/useRepository'
import BuildingBlocksEntityModel, { BuildingBlocksTypes } from '@/models/entities/buildingBlocksEntityModel'
import BaseEntityModel from '@/models/entities/baseEntityModel'
import TextInput, { inputTypes } from '../../components/inputs/textInput.vue'
import SelectInput from '../../components/inputs/selectInput.vue'
import BuildingBlockItem from '../list/buildingBlockItem.vue'
import TimeBadge from '../semantic/timeBadge.vue'
import EnumTypeFilter from '../../components/filters/enumTypeFilter.vue'
import DurationFilter from '../../components/filters/durationFilter.vue'
import ThemeRepository from '../../repositories/entities/themeRepository'
import CategoriesRepository from '../../repositories/entities/categoriesRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import SensetiveBadge from '../semantic/sensitiveBadge.vue'
import ckeditorView from '../../components/semantic/ckeditorView.vue'

export default defineComponent({
  name: 'select-building-block',
  components: {
    BaseOverview,
    TextInput,
    SelectInput,
    BuildingBlockItem,
    TimeBadge,
    ckeditorView,
    'enum-filter': EnumTypeFilter,
    DurationFilter,
    'sensitive-badge': SensetiveBadge
  },
  props: {
    value: Object as PropType<BaseEntityModel>
  },
  setup (props, { emit }) {
    const selectedBlock = ref<BaseEntityModel | undefined>()
    const filters : any = {
      type: { type: 'string', value: undefined, filterKey: 'type' },
      term: { type: 'string', value: undefined, filterKey: 'term' },
      duration: { type: 'objectString', value: undefined, filterKey: 'duration' },
      theme: { type: 'arrayEntity', value: undefined, filterKey: 'theme' },
      category: { type: 'arrayEntity', value: undefined, filterKey: 'category' }
    }

    const buildingBlockRepo: BuildingBlocskRepository = RepositoryFactory.get(BuildingBlocskRepository)
    const emptyBlock = ref<BuildingBlocksEntityModel | undefined>(undefined)
    const isDirectlySelectedFromMenu = ref<Boolean>(false)
    buildingBlockRepo.getEmptyBlock().then((emptyBlockreturn: BuildingBlocksEntityModel) => {
      emptyBlock.value = emptyBlockreturn
    })

    watch(
      () => props.value,
      () => {
        if (!isDirectlySelectedFromMenu.value) {
          selectedBlock.value = props.value
        }
        isDirectlySelectedFromMenu.value = false
      }
    )

    const moreInfo = async (id: string, EmptyBlock?: BuildingBlocksEntityModel) => {
      if (EmptyBlock) {
        selectedBlock.value = EmptyBlock
      } else {
        const { loading, doCall, result } = useRepository(
          BuildingBlocskRepository,
          callTypes.getSingel,
          {
            id: id
          }
        )

        await doCall()
        selectedBlock.value = result.value

      }
      emit('input', selectedBlock.value)
      selectedBlock.value = undefined
    }

    const selectBlock = async (id: string, EmptyBlock?: BuildingBlocksEntityModel) => {
      if (EmptyBlock) {
        emit('selectBlock', EmptyBlock)
      } else {
        const { loading, doCall, result } = useRepository(
          BuildingBlocskRepository,
          callTypes.getSingel,
          {
            id: id
          }
        )

        await doCall()
        emit('selectBlock', result.value)
        selectedBlock.value = undefined
        isDirectlySelectedFromMenu.value = true
      }

    }



    const goBack = () => {
      selectedBlock.value = undefined
    }

    return {
      BuildingBlocskRepository,
      moreInfo,
      goBack,
      selectedBlock,
      inputTypes,
      filters,
      ThemeRepository,
      CategoriesRepository,
      emptyBlock,
      selectBlock,
      BuildingBlocksTypes
    }
  }
})
