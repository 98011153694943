






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'sub-title',
  props: {
    label: String
  }
})
