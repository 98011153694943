




import usePermissions from '@/composables/usePermissions'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'sensitive-badge',
  setup () {
    const { can } = usePermissions()

    return {
      can
    }
  }
})
