
































import RepositoryFactory from '@/repositories/repositoryFactory'
import FileRepository from '@/repositories/entities/fileRepository'
import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import FileItem from './FileItem.vue'
import FileEntityModel from '@/models/entities/fileEntityModel'

export default defineComponent({
  name: 'file-upload',
  components: {
    'file-item': FileItem
  },
  props: {
    inputFiles: {
      type: Array as PropType<FileEntityModel[]>,
      required: false
    },
    isDetailView: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, context) {
    const files = ref<FileEntityModel[]>([])
    const file = ref<any>()

    if (props.inputFiles) {
      files.value = props.inputFiles
    }

    const uploadFile = (file: File) => {
      RepositoryFactory.get(FileRepository).uploadFile(file).then((res: FileEntityModel) => {
        res.contentType = file.type
        res.name = file.name
        res.size = file.size.toString()
        files.value.push(res)
      })
    }

    const deleteFileFromArray = (eventUuid: any) => {
      files.value.splice(files.value.findIndex(x => x.id === eventUuid), 1)
    }

    watch(() => files.value, () => {
      context.emit('addFiles', files.value)
    })

    return {
      files,
      file,
      uploadFile,
      deleteFileFromArray
    }
  }
})
