



































































import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import getValidationState from '../../composables/useValidationState'

export enum inputTypes {
    text = 'text',
    email = 'email',
    time = 'time'
}

export default defineComponent({
  name: 'text-input',
  props: {
    value: String,
    label: String,
    isRequired: Boolean,
    type: String as PropType<inputTypes>,
    id: String,
    disabled: Boolean,
    textarea: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => { return { required: true, min: 3 } }
    }
  },
  setup (props, { emit }) {
    const hours = ref<string>('00')
    const minutes = ref<string>('00')
    let input = ref(props.value)
    if (props.value) {
      var res = props.value.split(':')
      hours.value = res[0]
      minutes.value = res[1]
    }
    let displayHour = hours.value
    let displayMinutes = minutes.value

    watch(input, value => {
      emit('input', value)
    })

    watch(() => props.value, () => { input.value = props.value })

    watch(() => hours.value, () => {
      displayHour = hours.value
      if (hours.value.length === 0) {
        displayHour = '00'
      }
      if (hours.value.length === 1) {
        displayHour = ('0' + hours.value)
      }
      if (hours.value.length > 2) {
        displayHour = '23'
      }
      input.value = displayHour + ':' + displayMinutes
    })

    watch(() => minutes.value, () => {
      displayMinutes = minutes.value
      if (minutes.value.length === 0) {
        displayMinutes = '00'
      }
      if (minutes.value.length === 1) {
        displayMinutes = ('0' + minutes.value)
      }
      if (minutes.value.length > 2) {
        displayMinutes = '59'
      }
      input.value = displayHour + ':' + displayMinutes
    })

    return {
      getValidationState,
      input,
      hours,
      minutes
    }
  }
})
