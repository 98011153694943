

































import { defineComponent, PropType } from '@vue/composition-api'
import BuildingBlocksEntityModel from '@/models/entities/buildingBlocksEntityModel'
import TimeBadge from '../../components/semantic/timeBadge.vue'
import SensetiveBadge from '../../components/semantic/sensitiveBadge.vue'
import DisabledBadge from '../../components/semantic/disabledBadge.vue'
import usePermissions from '@/composables/usePermissions'
import statusBadge from '../semantic/statusBadge.vue'

export default defineComponent({
  name: 'building-block-item',
  props: {
    block: {
      type: Object as PropType<BuildingBlocksEntityModel>,
      required: true
    },
    hideInfo: {
      type: Boolean,
      default: false
    },
    readMore: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TimeBadge,
    statusBadge,
    'sensitive-badge': SensetiveBadge,
    'disabled-badge': DisabledBadge
  },
  setup () {
    const { can } = usePermissions()

    return {
      can
    }
  }
})
