














import FileEntityModel from '@/models/entities/fileEntityModel'
import FileRepository from '@/repositories/entities/fileRepository'
import RepositoryFactory from '@/repositories/repositoryFactory'
import { defineComponent, ref, PropType } from '@vue/composition-api'
import { saveAs } from 'file-saver'

export default defineComponent({
  name: 'file-item',
  props: {
    file: {
      type: Object as PropType<FileEntityModel>,
      required: true
    },
    isDetailView: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, context) {

    const downloadFile = () => {
      if (props?.file?.id) {
        RepositoryFactory.get(FileRepository).downloadFile(props.file.id).then((file: Blob) => {
          saveAs(file, props.file.name)
        })
      }
    }

    const deleteFromArray = () => {
      context.emit('deleteFileFromArray', props.file.id)
    }

    return {
      downloadFile,
      deleteFromArray
    }
  }
})
