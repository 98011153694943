





































import { defineComponent, ref, Ref, watch, PropType } from '@vue/composition-api'
import Multiselect from 'vue-multiselect'
import BaseEntityModel from '../../models/entities/baseEntityModel'
import BaseRepository from '../../repositories/baseRepository'
import getValidationState from '../../composables/useValidationState'
import useRepository from '../../composables/useRepository'

export default defineComponent({
  name: 'select-input',
  props: {
    label: String,
    isRequired: Boolean,
    id: String,
    multiple: Boolean,
    searchable: {
      type: Boolean,
      default: false
    },
    repo: {
      type: Function as PropType<new (...params: any[]) => BaseRepository>,
      default: undefined
    },
    options: {
      type: [
        Object as () => Array<any>,
        Array]
    },
    rules: {
      type: Object,
      default: () => { return { required: true } }
    },
    value: {
      type: [
        Object as () => BaseEntityModel | BaseEntityModel[] | undefined,
        Array as () => BaseEntityModel | BaseEntityModel[] | undefined,
        String
      ]
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: {
    'multi-select': Multiselect
  },
  setup (props, { emit }) {
    const input = ref<BaseEntityModel | BaseEntityModel[] | string |undefined>(props.value)
    let loadingLocal : boolean | Ref<Boolean> = false
    let optionsValue = ref<Array<any>>([])

    if (props.repo) {
      const { loading, results, doCall } = useRepository(props.repo)
      optionsValue = results
      loadingLocal = loading
      doCall()
    }

    if (props.options) {
      optionsValue.value = props.options
    }

    watch(input, value => {
      emit('input', value)
    })

    watch(() => props.value, () => {
      input.value = props.value
    })


    return {
      input,
      loadingLocal,
      optionsValue,
      getValidationState
    }
  }
})
