































import { defineComponent } from '@vue/composition-api'
import CategoriesRepository from '../../repositories/entities/privateCategoriesRepository'
import BaseOverview from '../../components/base-views/baseOverview.vue'
import DisabledBadge from '../../components/semantic/disabledBadge.vue'
import usePermissions from '@/composables/usePermissions'

export default defineComponent({
  name: 'categories-overview',
  components: {
    BaseOverview,
    'disabled-badge': DisabledBadge
  },
  setup () {
    const { can } = usePermissions()

    return {
      CategoriesRepository,
      can
    }
  }
})
