





























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'custom-collapse',
  props: {
    title: String,
    id: String,
    visible: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    extraClass: {
      type: String,
      required: false
    }
  }
})
