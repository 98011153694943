






















import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import getValidationState from '../../composables/useValidationState'

export default defineComponent({
  name: 'time-input',
  props: {
    value: String,
    label: String,
    id: String,
    rules: {
      type: Object,
      default: () => { return { required: true, min: 3 } }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {

    let input = ref(props.value)

    watch(input, value => {
      emit('input', value)
    })

    watch(() => props.value, () => { input.value = props.value })

    return {
      getValidationState,
      input
    }
  }
})
