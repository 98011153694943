
























































































import PrivateBuildingBlocskRepository from '../../repositories/entities/privateBuildingBlocskRepository'
import BuildingBlocskRepository from '../../repositories/entities/buildingBlocskRepository'
import BlockStatusRepository from '../../repositories/entities/blockStatusRepository'
import CategoriesRepository from '../../repositories/entities/categoriesRepository'
import { BuildingBlocksTypes } from '@/models/entities/buildingBlocksEntityModel'
import TextInput, { inputTypes } from '../../components/inputs/textInput.vue'
import { transitionTypes } from '../../repositories/withTransitionRepository'
import BlockStatusEntityModel from '@/models/entities/blockStatusEntityModel'
import BuildingBlockItem from '../../components/list/buildingBlockItem.vue'
import ThemeRepository from '../../repositories/entities/themeRepository'
import EnumTypeFilter from '../../components/filters/enumTypeFilter.vue'
import DurationFilter from '../../components/filters/durationFilter.vue'
import BaseOverview from '../../components/base-views/baseOverview.vue'
import SelectInput from '../../components/inputs/selectInput.vue'
import { defineComponent, ref } from '@vue/composition-api'
import usePermissions from '@/composables/usePermissions'
import store from '@/store/store'
import { useSlugify } from '../../helpers/slugHelper'

export default defineComponent({
  name: 'building-block-overview',
  components: {
    BaseOverview,
    SelectInput,
    TextInput,
    BuildingBlockItem,
    'enum-filter': EnumTypeFilter,
    DurationFilter
  },
  props: {
    isReadyForPublictionOverview: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    window.scrollTo(0, 0)
    const { getSluggedTitle } = useSlugify()
    const status = ref<any>(undefined)

    if (props.isReadyForPublictionOverview) {
      status.value = new BlockStatusEntityModel('Publicatie aangevraagd', 'PUBLICATION_REQUESTED', 'PUBLICATION_REQUESTED')
    }

    const filters = ref<Object>({
      type: { type: 'string', value: undefined, filterKey: 'type' },
      term: { type: 'string', value: undefined, filterKey: 'term' },
      duration: { type: 'objectString', value: undefined, filterKey: 'duration' },
      status: { type: 'entity', value: status.value, filterKey: 'status' },
      createdBy: { type: 'string', value: undefined, filterKey: 'created_by' },
      theme: { type: 'arrayEntity', value: undefined, filterKey: 'theme' },
      category: { type: 'arrayEntity', value: undefined, filterKey: 'category' }
    })
    const { can } = usePermissions()
    const isLoggedIn = store.getters['openid/isLoggedIn']
    const repo = isLoggedIn ? PrivateBuildingBlocskRepository : BuildingBlocskRepository

    let myId

    if (isLoggedIn) {
      myId = store.getters['user/getUser'].id
    }

    return {
      BlockStatusRepository,
      CategoriesRepository,
      BuildingBlocksTypes,
      ThemeRepository,
      transitionTypes,
      getSluggedTitle,
      inputTypes,
      isLoggedIn,
      filters,
      repo,
      myId,
      can
    }
  }
})
