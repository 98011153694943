






































import { defineComponent } from '@vue/composition-api'
import usePermissions from '../../composables/usePermissions'

export default defineComponent({
  name: 'custom-header',
  setup () {
    const { can } = usePermissions()

    return {
      can
    }
  }
})
