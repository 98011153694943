






























































import { defineComponent, ref } from '@vue/composition-api'
import { useRouter } from '../../composables/useRouter'
import useRepository, { callTypes } from '../../composables/useRepository'
import TimeBadge from '../../components/semantic/timeBadge.vue'
import usePermissions from '@/composables/usePermissions'
import statusBadge from '../../components/semantic/statusBadge.vue'
import customCollapse from '../../components/semantic/customCollapse.vue'
import ckeditorView from '../../components/semantic/ckeditorView.vue'
import BuildingBlocksRepository from '@/repositories/entities/buildingBlocskRepository'
import useToast from '@/composables/useToast'
import CreatedBy from '../../components/semantic/createdBy.vue'
import SensetiveBadge from '../../components/semantic/sensitiveBadge.vue'

export default defineComponent({
  props: {
    workshopId: String
  },
  components: {
    TimeBadge,
    statusBadge,
    customCollapse,
    ckeditorView,
    CreatedBy,
    'sensitive-badge': SensetiveBadge
  },
  setup (props, { emit, root }) {
    const { route, router } = useRouter()
    const { can } = usePermissions()
    const toast = useToast(root)
    const { loading, doCallWithLoginRetry, result } = useRepository(BuildingBlocksRepository, callTypes.getSingel, { id: route.value.params.buildingBlockId })

    doCallWithLoginRetry('bouwblokken/' + route.value.params.itemTitle + '/' + route.value.params.buildingBlockId).catch(() => {
      toast.send('U kan deze bouwsteen niet bekijken', 'danger')
      router.push({ name: 'WerkwinkelOverview' })
    })


    return {
      result,
      loading,
      can
    }
  }
})
