


















































































































































































import { defineComponent, reactive, ref, watch, PropType, computed } from '@vue/composition-api'
import selectBuildingBlock from './selectBuildingBlock.vue'
import BuildingBlocksEntityModel, { BuildingBlocksTypes } from '@/models/entities/buildingBlocksEntityModel'
import TextInput, { inputTypes } from '../../components/inputs/textInput.vue'
import SelectInput from '../../components/inputs/selectInput.vue'
import ckEditor from '../../components/inputs/ckEditor.vue'
import TimeInput from '../../components/inputs/timeInput.vue'
import CategoryRepository from '../../repositories/entities/categoriesRepository'
import ThemeRepository from '../../repositories/entities/themeRepository'
import customCollapse from '../../components/semantic/customCollapse.vue'

export default defineComponent({
  name: 'builing-blocks',
  components: {
    selectBuildingBlock,
    TextInput,
    ckEditor,
    TimeInput,
    SelectInput,
    customCollapse
  },
  props: {
    value: Array as PropType<BuildingBlocksEntityModel[]>,
    validationState: {
      type: Boolean,
      default: false
    }
  },
  setup ({ value }, { emit }) {
    let buildingBlocks = ref<BuildingBlocksEntityModel[]>(value || [])
    const selectedBlock = ref<BuildingBlocksEntityModel | undefined>()
    const showModal = ref<boolean>(false)

    const emitBlock = (block: BuildingBlocksEntityModel) => {
      const order : number = buildingBlocks.value.length > 0 ? buildingBlocks.value.length : 0
      buildingBlocks.value.push(BuildingBlocksEntityModel.createNewFromTemplate(block, order))
      // emit('input', buildingBlocks)
      hideModel()
    }

    const addSelectedBlock = () => {
      selectedBlock.value && emitBlock(selectedBlock.value)
      selectedBlock.value = undefined
    }

    const hideModel = () => { showModal.value = false }

    const deleteBlock = (indexToDelete: number, order: number) => {
      buildingBlocks.value.splice(indexToDelete, 1)

      buildingBlocks.value.forEach((block: any) => {
        if (block.order && block.order > order) {
          block.order = block.order - 1
        }
      })

    }

    const goBack = () => { selectedBlock.value = undefined }

    const enableEditBlock = (block: BuildingBlocksEntityModel) => { block.editable = true }

    const compare = (a, b) => {
      if (a.order < b.order) {
        return -1
      }
      if (a.order > b.order) {
        return 1
      }
      return 0
    }

    buildingBlocks.value.sort(compare)


    const isLastBlock = (order: number) : Boolean => order === buildingBlocks.value.length - 1
    const isFirstBlock = (order: number) : Boolean => order === 0

    const orderDown = (block: BuildingBlocksEntityModel) => {
      if (block.order !== undefined && !isLastBlock(block.order)) {
        const newOrder = block.order + 1
        updateOrder(block, newOrder)
      }
    }
    const orderUp = (block: BuildingBlocksEntityModel) => {
      if (block.order && !isFirstBlock(block.order)) {
        const newOrder = block.order - 1
        updateOrder(block, newOrder)
      }
    }

    const updateOrder = (block: BuildingBlocksEntityModel, newOrder: number) => {
      const orgOrder = block.order
      const blockUp = buildingBlocks.value.filter((filterBlock: any) => {
        if (filterBlock.order === newOrder) {
          filterBlock.order = orgOrder
        }
      })
      block.order = newOrder
      buildingBlocks.value.sort(compare)
    }

    return {
      addSelectedBlock,
      buildingBlocks,
      selectedBlock,
      hideModel,
      showModal,
      inputTypes,
      deleteBlock,
      goBack,
      enableEditBlock,
      orderUp,
      orderDown,
      isFirstBlock,
      isLastBlock,
      BuildingBlocksTypes,
      CategoryRepository,
      ThemeRepository,
      emitBlock
    }
  }
})
