














































































































































import { reactive, defineComponent, ref } from '@vue/composition-api'
import TextInput, { inputTypes } from '../../components/inputs/textInput.vue'
import TimeInput from '../../components/inputs/timeInput.vue'
import SelectInput from '../../components/inputs/selectInput.vue'
import { transitionTypes } from '../../repositories/withTransitionRepository'
import BuildingBlocskRepository from '../../repositories/entities/privateBuildingBlocskRepository'
import ckEditor from '../../components/inputs/ckEditor.vue'
import BuildingBlocksEntityModel, { BuildingBlocksTypes } from '@/models/entities/buildingBlocksEntityModel'
import BaseForm from '../../components/base-views/baseForm.vue'
import CategoryRepository from '../../repositories/entities/categoriesRepository'
import ThemeRepository from '../../repositories/entities/themeRepository'
import usePermissions from '@/composables/usePermissions'
import useTransitions from '@/composables/useTransitions'
import { useRouter } from '@/composables/useRouter'
import BuildingBlocksRepository from '@/repositories/entities/buildingBlocskRepository'
import { useEnum } from '@/composables/useEnum'
import { RETRY_REDIRECT } from '../../composables/useRepository'

export default defineComponent({
  name: 'building-blocks-form',
  components: {
    BaseForm,
    TextInput,
    ckEditor,
    SelectInput,
    TimeInput
  },
  setup (props, { emit, root }) {
    sessionStorage.setItem(RETRY_REDIRECT, 'bouwblokken/nieuw')
    const { route } = useRouter()
    const isEdit = ref<boolean>()
    isEdit.value = !!route.value.params['buildingBlockId']
    const form = reactive<BuildingBlocksEntityModel>(BuildingBlocksEntityModel.deserialize({
      title: null,
      id: null,
      duration: '1:30:00',
      description: null,
      is_sensitive: false,
      category: null,
      theme: null,
      type: BuildingBlocksTypes.THEMATIC,
      is_disabled: false,
      status: {
        id: 'PRIVATE',
        label: 'Privé',
        value: 'PRIVATE'
      }
    }))
    const { getTypesArray } = useEnum(BuildingBlocksTypes)
    const types : String[] = getTypesArray()
    const { can } = usePermissions()
    const redirectRoute = 'BuildingBlockView'
    const { afterSubmit, saveAndPublish } = useTransitions(BuildingBlocksRepository, redirectRoute, root)

    return {
      BuildingBlocksEntityModel,
      BuildingBlocskRepository,
      BuildingBlocksTypes,
      CategoryRepository,
      ThemeRepository,
      transitionTypes,
      saveAndPublish,
      redirectRoute,
      afterSubmit,
      inputTypes,
      types,
      form,
      can,
      isEdit
    }
  }
})
