




import { defineComponent } from '@vue/composition-api'


export default defineComponent({
  name: 'ckeditor-view',
  props: {
    content: String
  }
})
