






















































import { defineComponent, PropType } from '@vue/composition-api'
import WorkshopRepository from '../../repositories/entities/workshopRepository'
import privatePublishedWorkshopRepository from '../../repositories/entities/privatePublishedWorkshopRepository'
import WorkshopItem from '../../components/list/workshopItem.vue'
import ThemeRepository from '../../repositories/entities/themeRepository'
import SelectInput from '../../components/inputs/selectInput.vue'
import TextInput, { inputTypes } from '../../components/inputs/textInput.vue'
import BaseOverview from '../../components/base-views/baseOverview.vue'
import DurationFilter from '../../components/filters/durationFilter.vue'
import store from '@/store/store'
import RepositoryFactory from '@/repositories/repositoryFactory'
import usePermissions from '@/composables/usePermissions'

export default defineComponent({
  name: 'workshop-overview',
  components: {
    BaseOverview,
    WorkshopItem,
    SelectInput,
    TextInput,
    DurationFilter
  },
  props: {
    workshopReposioryType: {
      type: Function as PropType<new (...params: any[]) => WorkshopRepository>,
      required: true,
      default: WorkshopRepository
    },
    showCreate: {
      type: Boolean,
      default: true
    }
  },
  setup ({ showCreate, workshopReposioryType }) {
    const { can } = usePermissions()
    const filters : any = {
      theme: { type: 'arrayEntity', value: undefined, filterKey: 'theme' },
      term: { type: 'string', value: undefined, filterKey: 'term' },
      duration: { type: 'objectString', value: undefined, filterKey: 'duration' },
      isSensitive: { type: 'string', value: undefined, filterKey: 'is_sensitive' }
    }

    const createRoute = showCreate ? 'WerkwinkelCreate' : ''
    const showStatus = showCreate

    const optionObject = {
      'minder dan een uur':
        {
          'id': 'minder dan een uur',
          'duration_start': '00:00:00',
          'duration_end': '01:00:00'
        },
      '1 tot 2 uur':
        {
          'id': '1 tot 2 uur',
          'duration_start': '01:00:00',
          'duration_end': '02:00:00'
        },
      'meer dan 2 uur':
        {
          'id': 'meer dan 2 uur',
          'duration_start': '02:00:00',
          'duration_end': '99:00:00'
        }
    }
    let repo : any = workshopReposioryType
    if (RepositoryFactory.get(repo).id === 'published-workshops' && store.getters['openid/isLoggedIn']) {
      repo = privatePublishedWorkshopRepository
    }

    return {
      filters,
      repo,
      ThemeRepository,
      inputTypes,
      createRoute,
      showStatus,
      optionObject,
      can
    }
  }
})
