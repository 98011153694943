




























































































































































































import BuildingBlocksRepository from '@/repositories/entities/buildingBlocskRepository'
import BuildingBlocksEntityModel from '@/models/entities/buildingBlocksEntityModel'
import WorkshopRepository from '../../repositories/entities/workshopRepository'
import WorkshopEntityModel from '../../models/entities/workshopEntityModel'
import useRepository, { callTypes } from '../../composables/useRepository'
import customCollapse from '../../components/semantic/customCollapse.vue'
import SensetiveBadge from '../../components/semantic/sensitiveBadge.vue'
import ckeditorView from '../../components/semantic/ckeditorView.vue'
import statusBadge from '../../components/semantic/statusBadge.vue'
import RepositoryFactory from '@/repositories/repositoryFactory'
import TimeBadge from '../../components/semantic/timeBadge.vue'
import CreatedBy from '../../components/semantic/createdBy.vue'
import FileUpload from '@/components/semantic/FileUpload.vue'
import { defineComponent, ref } from '@vue/composition-api'
import usePermissions from '@/composables/usePermissions'
import { useRouter } from '../../composables/useRouter'
import useDownload from '../../composables/useDownload'
import { useSlugify } from '../../helpers/slugHelper'
import { getModule } from 'vuex-module-decorators'
import useToast from '@/composables/useToast'
import userModule from '@/store/userModule'
import store from '@/store/store'
import moment from 'moment'

export default defineComponent({
  props: {
    workshopId: String
  },
  components: {
    TimeBadge,
    statusBadge,
    customCollapse,
    ckeditorView,
    CreatedBy,
    'sensitive-badge': SensetiveBadge,
    'file-upload': FileUpload
  },
  setup (props, { emit, root }) {
    const { getSluggedTitle } = useSlugify()
    const { route, router } = useRouter()
    const { can } = usePermissions()
    const toast = useToast(root)
    const { loading, doCallWithLoginRetry, doCallWithLogin, result } = useRepository(WorkshopRepository, callTypes.getSingel, { id: route.value.params.workshopId })
    const necessitiesOpen = ref<Boolean>(true)
    const { DownloadFile } = useDownload()
    const BuildingBlocksToPublish = ref<Array<BuildingBlocksEntityModel>>([])
    const buildingBlockRepo: BuildingBlocksRepository = RepositoryFactory.get(BuildingBlocksRepository)
    const pdfFileName = ref<string>('default_pdf_name')
    const userStoreModule = getModule(userModule, store)
    const user = ref<any>()

    window.scrollTo(0, 0)

    user.value = userStoreModule.getUser

    const login = () => {
      doCallWithLogin('werkwinkels/' + route.value.params.workshopId)
    }

    const fetchWorkshop = () => {
      doCallWithLoginRetry('werkwinkels/' + route.value.params.itemTitle + '/' + route.value.params.workshopId).catch(() => {
        toast.send('U kan deze werkwinkel niet bekijken', 'danger')
        router.push({ name: 'WerkwinkelOverview' })
      })
    }

    fetchWorkshop()

    root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'accordion-benodigdheden') {
        necessitiesOpen.value = isJustShown
      }
    })

    const goToNecessities = () => {
      !necessitiesOpen.value && root.$emit('bv::toggle::collapse', 'accordion-benodigdheden')
      root.$scrollTo('#necessities')
    }

    const DownloadPDF = (workshop: WorkshopEntityModel) => RepositoryFactory.get(WorkshopRepository).getDownload(workshop).then((repsonse: any) => {
      if (workshop.title) {
        pdfFileName.value = workshop.title.replace(' ', '_').toLowerCase() + '_' + moment().format('YYYY_MM_DD')
      }
      DownloadFile(repsonse, pdfFileName.value)
    })

    const toggle = (block:any) => {
      if (BuildingBlocksToPublish.value.includes(block)) {
        var index = BuildingBlocksToPublish.value.indexOf(block)
        if (index !== -1) {
          BuildingBlocksToPublish.value.splice(index, 1)
        }
      } else {
        BuildingBlocksToPublish.value.push(block)
      }
    }

    const askPublication = (block: any) => {
      BuildingBlocksToPublish.value.forEach(block => {
        let buildingBlock = block
        buildingBlock.id = undefined
        const { doCall, result } = useRepository(BuildingBlocksRepository, callTypes.create, { model: buildingBlock })
        doCall().then(() => {
          // @ts-ignore
          buildingBlockRepo.requestPublication(result.value.id).then(() => {
            toast.send('Publicatie aangevraagd voor:' + result.value?.title, 'success')
          }).catch(() => {
            toast.send('Aanvraging publicatie mislukt voor: ' + result.value?.title, 'danger')
          })
        }).catch(() => {
          toast.send('Aanvraging publicatie mislukt voor: ' + result.value?.title, 'danger')
        })
      })
      BuildingBlocksToPublish.value = []
    }

    return {
      BuildingBlocksToPublish,
      goToNecessities,
      askPublication,
      getSluggedTitle,
      DownloadPDF,
      loading,
      result,
      toggle,
      login,
      user,
      can
    }
  }
})
