















































import usePermissions from '@/composables/usePermissions'
import useRepository, { callTypes } from '@/composables/useRepository'
import { useRouter } from '@/composables/useRouter'
import BaseEntityModel from '@/models/entities/baseEntityModel'
import BaseRepository from '@/repositories/baseRepository'
import { defineComponent, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'history-modal',
  props: {
    paramIdentifier: {
      type: String,
      required: true
    },
    repo: {
      type: Function as PropType<new (...params: any[]) => BaseRepository>,
      required: true
    }
  },
  setup ({ paramIdentifier, repo }, { emit }) {
    const { can } = usePermissions()
    const { route, router } = useRouter()
    const showModal = ref<boolean>(false)
    const { loading: loadingHistory, doCall: doCallHistory, historyResults: historyResult } = useRepository(
      repo,
      callTypes.history,
      { id: route.value.params[paramIdentifier]
      })

    const setOldVersion = (result: BaseEntityModel) => {
      emit('setOldVersion', result)
      showModal.value = false
    }

    const showModalAndCallHistory = () => {
      showModal.value = true
      doCallHistory()
    }

    return {
      setOldVersion,
      showModalAndCallHistory,
      showModal,
      can,
      historyResult
    }
  }
})
