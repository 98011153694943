























import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import getValidationState from '../../composables/useValidationState'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Link from '@ckeditor/ckeditor5-link/src/link'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'


export default defineComponent({
  name: 'ck-editor',
  props: {
    value: String,
    label: String,
    isRequired: Boolean,
    id: String,
    disabled: Boolean,
    toolbarItems: Array,
    big: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Object,
      default: () => { return { required: true, min: 3 } }
    }
  },
  setup ({ value, toolbarItems }, { emit, root }) {
    let input = ref<string | undefined>(value)
    const editor = ref<ClassicEditor>(ClassicEditor)

    watch(input, value => {
      emit('input', value)
    })

    let config : any = {
      plugins: [
        EssentialsPlugin,
        Bold,
        Italic,
        Heading,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Link,
        ListStyle,
        Paragraph,
        SimpleUploadAdapter,
        Alignment,
        Table,
        TableToolbar
      ],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h3', title: 'Heading 1', class: 'ck-heading_heading3' },
          { model: 'heading2', view: 'h4', title: 'Heading 2', class: 'ck-heading_heading4' },
          { model: 'heading3', view: 'h5', title: 'Heading 3', class: 'ck-heading_heading5' }
        ]
      },
      toolbar: {
        items: [
          'heading',
          '|',
          'alignment',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'imageUpload',
          'undo',
          'redo',
          'insertTable'
        ]
      },
      image: {
        // maxFileSize: 102400,
        toolbar: [
          'imageStyle:full',
          'imageTextAlternative'
        ]
      },
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: root.$store.state.config.config.api.baseUrl + '/' + root.$store.state.config.config.api.apiSuffix + 'files/upload/',
        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
          Authorization: 'Bearer ' + root.$store.state.openid.accessToken
        }
      }
    }

    if (toolbarItems) {
      config['toolbar'] = {
        items: toolbarItems
      }
    }

    return {
      getValidationState,
      input,
      editor,
      config
    }
  }
})
