









































import { defineComponent } from '@vue/composition-api'
import ThemeRepository from '../../repositories/entities/privateThemeRepository'
import BaseOverview from '../../components/base-views/baseOverview.vue'
import TextInput, { inputTypes } from '../../components/inputs/textInput.vue'
import DisabledBadge from '../../components/semantic/disabledBadge.vue'
import usePermissions from '@/composables/usePermissions'

export default defineComponent({
  name: 'themes-overview',
  components: {
    BaseOverview,
    TextInput,
    'disabled-badge': DisabledBadge
  },
  setup () {
    const filters : any = {
      term: { type: 'string', value: undefined, filterKey: 'term' }
    }
    const { can } = usePermissions()

    return {
      ThemeRepository,
      filters,
      inputTypes,
      can
    }
  }
})
