














































































































































import { reactive, defineComponent, ref } from '@vue/composition-api'
import TextInput, { inputTypes } from '../../components/inputs/textInput.vue'
import TimeInput from '../../components/inputs/timeInput.vue'
import SelectInput from '../../components/inputs/selectInput.vue'
import BuildingBlockInput from '../../components/inputs/buildingBlockInput.vue'
import ckEditor from '../../components/inputs/ckEditor.vue'
import ThemeRepository from '../../repositories/entities/themeRepository'
import WorkshopEntityModel from '../../models/entities/workshopEntityModel'
import BaseForm from '../../components/base-views/baseForm.vue'
import { transitionTypes } from '../../repositories/withTransitionRepository'
import WorkshopRepository from '../../repositories/entities/workshopRepository'
import subTitle from '../../components/semantic/subTitle.vue'
import getValidationState from '../../composables/useValidationState'
import statusBadge from '../../components/semantic/statusBadge.vue'
import TeamRepository from '../../repositories/entities/teamRepository'
import usePermissions from '@/composables/usePermissions'
import useTransitions from '@/composables/useTransitions'
import { useRouter } from '@/composables/useRouter'
import { RETRY_REDIRECT } from '../../composables/useRepository'
import FileUpload from '@/components/semantic/FileUpload.vue'

export default defineComponent({
  name: 'workshop-form',
  components: {
    TextInput,
    SelectInput,
    ckEditor,
    BaseForm,
    TimeInput,
    BuildingBlockInput,
    subTitle,
    statusBadge,
    'file-upload': FileUpload
  },
  setup (props, { root }) {
    sessionStorage.setItem(RETRY_REDIRECT, 'werkwinkels/nieuw')
    const { route } = useRouter()
    const isEdit = ref<boolean>()
    isEdit.value = !!route.value.params['workshopId']
    const form = reactive<WorkshopEntityModel>(WorkshopEntityModel.deserialize({
      title: null,
      id: null,
      shortDescription: null,
      description: null,
      necessities: null,
      theme: null,
      buildingBlocks: [],
      workshop_status_type: 'PRIVATE',
      approvingTeam: null
    }))
    const { can } = usePermissions()
    const redirectRoute = 'WerkwinkelView'
    const { afterSubmit, saveAndPublish } = useTransitions(WorkshopRepository, redirectRoute, root)
    const cancelRoute = ref<String>('MijnWerkwinkelOverview')
    if (route.value.meta.from) {
      cancelRoute.value = route.value.meta.from
    }
    const addFiles = (event: any, formData: any) => {
      formData.files = event
    }
    return {
      inputTypes,
      form,
      WorkshopRepository,
      ThemeRepository,
      WorkshopEntityModel,
      afterSubmit,
      saveAndPublish,
      transitionTypes,
      getValidationState,
      TeamRepository,
      redirectRoute,
      can,
      cancelRoute,
      isEdit,
      addFiles
    }
  }
})
