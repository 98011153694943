




































import { reactive, defineComponent } from '@vue/composition-api'
import TextInput, { inputTypes } from '../../components/inputs/textInput.vue'
import CategoriesRepository from '../../repositories/entities/categoriesRepository'
import ckEditor from '../../components/inputs/ckEditor.vue'
import CategoryEntityModel from '@/models/entities/categoryEntityModel'
import BaseForm from '../../components/base-views/baseForm.vue'
import usePermissions from '@/composables/usePermissions'

export default defineComponent({
  name: 'category-form',
  components: {
    BaseForm,
    TextInput,
    ckEditor
  },
  setup (props, { emit }) {
    const form = reactive<CategoryEntityModel>(CategoryEntityModel.deserialize({
      title: null,
      id: null
    }))
    const { can } = usePermissions()

    return {
      inputTypes,
      CategoryEntityModel,
      CategoriesRepository,
      form,
      can
    }
  }
})
